import axios from './../../request/axios.js'
import api from './../../request/api.js'

const GradeClassService = {
  getNewGradeClass: function (params) {
    return axios.post(api.management.GradeClass.getNewGradeClass, params)
  },
  getHistoryStudent: function (params) {
    return axios.post(api.management.GradeClass.getHistoryStudent, params)
  },
  // 恢复学生
  recoverStudent: function (params) {
    return axios.post(api.management.GradeClass.recoverStudent, params)
  },
  // 搜索历史学生数据
  searchHistoryStudent: function (params) {
    return axios.post(api.management.GradeClass.searchHistoryStudent, params)
  },
  // 班级学生批量导入
  importClassStudents: function (params) {
    return axios.post(api.management.GradeClass.importClassStudents, params)
  },
  // 学生历史数据走势图
  trendChart: function (params) {
    return axios.post(api.management.GradeClass.trendChart, params)
  },
  // 获取学生测试历史数据
  getChildrenTestHistory: function (params) {
    return axios.post(api.management.GradeClass.getChildrenTestHistory, params)
  }
}
export default GradeClassService
